import React from 'react';

// === Components === //
import Page from '@components/layout/Page';
import HomepageHero from '@components/sections/HomepageHero';
import HomepageSupportForRefugees from '@components/sections/HomepageSupportForRefugees';
import HomepageTextWithImageColumns from '@components/sections/HomepageTextWithImageColumns';
import HomepageHowToSubmitInitiative from '@components/sections/HomepageHowToSubmitInitiative';
import HomepageMap from '@components/sections/HomepageMap';
import HomepageWhatHaveWeDone from '@components/sections/HomepageWhatHaveWeDone';
import HomepageCompaniesBoxes from '@components/sections/HomepageCompaniesBoxes';

// === Helpers === //
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Homepage = () => {
  const { t } = useTranslation();

  const metaData = {
    title: t('home_meta_title'),
    description: t('home_meta_description'),
    keywords: t('home_meta_keywords'),
  };

  return (
    <Page metaData={metaData} activePage="home">
      <div>
        <HomepageHero />
        <HomepageSupportForRefugees />
        <HomepageTextWithImageColumns />
        <HomepageHowToSubmitInitiative />
        <HomepageMap />
        <HomepageWhatHaveWeDone />
        <HomepageCompaniesBoxes />
      </div>
    </Page>
  );
};

export default Homepage;
