import React from 'react';

// === Assets === //
import uaFlagLong from '@assets/img/ua-flag-long.png';
import uaFlagLongMobile from '@assets/img/ua-flag-long-mobile.png';

// === Components === //
import Container from '@components/common/Container';
import Paragraph from '@components/common/Paragraph';
import ButtonLink from '@components/common/ButtonLink';

// === Helpers === //
import { useTranslation } from 'gatsby-plugin-react-i18next';

// === Styles === //
import styled from '@emotion/styled';
import { breakpoints, colors, respondFrom } from '@styles';
import { css } from '@emotion/react';

const HWHWDWrapper = styled.div`
  display: flex;
  flex-flow: column;
  padding: 40px 40px 97px;
  box-shadow: 0px 4px 65px rgba(0, 0, 0, 0.07);
  margin-bottom: 80px;
  background-image: url(${uaFlagLongMobile});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: ${colors.ui.second};

  ${respondFrom(
    breakpoints.md,
    css`
      background-image: url(${uaFlagLong});
    `
  )};

  ${respondFrom(
    breakpoints.lg,
    css`
      flex-flow: row;
      align-items: flex-start;
      justify-content: space-between;
    `
  )};

  ${respondFrom(
    breakpoints.xl,
    css`
      padding: 60px 82px 98px;
    `
  )};
`;

const HWHWDHeader = styled.div`
  margin-bottom: 32px;

  ${respondFrom(
    breakpoints.lg,
    css`
      max-width: 300px;
      margin-bottom: 0;
    `
  )};

  ${respondFrom(
    breakpoints.xl,
    css`
      max-width: 350px;
    `
  )};

  h3 {
    margin: 0;
    color: ${colors.white};
  }
`;

const HWHWDDescription = styled.div`
  color: ${colors.white};
  margin-bottom: 37px;

  ${respondFrom(
    breakpoints.lg,
    css`
      max-width: 300px;
      margin-bottom: 0;
      padding: 0 15px;
    `
  )};

  ${respondFrom(
    breakpoints.xl,
    css`
      max-width: 350px;
    `
  )};
`;

const HWHWDButton = styled.div`
  text-align: center;
`;

const HomepageWhatHaveWeDone = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <HWHWDWrapper>
        <HWHWDHeader>
          <h3>{t('home_what_have_we_done_title')}</h3>
        </HWHWDHeader>
        <HWHWDDescription>
          <Paragraph htmlAsString={t('home_what_have_we_done_description')} className="m16" />
        </HWHWDDescription>
        <HWHWDButton>
          <ButtonLink to={t('home_what_have_we_done_cta_link')} external={true} variant="light">
            <>{t('home_what_have_we_done_cta_label')}</>
          </ButtonLink>
        </HWHWDButton>
      </HWHWDWrapper>
    </Container>
  );
};

export default HomepageWhatHaveWeDone;
