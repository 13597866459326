import React from 'react';

// === Assets === //
import ukraineMap from '@assets/img/ukraine-map.png';
import ukraineMapMobile from '@assets/img/ukraine-map-mobile.png';

// === Components === //
import Container from '@components/common/Container';
import Paragraph from '@components/common/Paragraph';
import ButtonLink from '@components/common/ButtonLink';

// === Helpers === //
import { useTranslation } from 'gatsby-plugin-react-i18next';

// === Styles === //
import styled from '@emotion/styled';
import { breakpoints, respondFrom } from '@styles';
import { css } from '@emotion/react';

const HomepageHeroWrapper = styled.div`
  position: relative;
  padding-top: 230px;
  padding-bottom: 45px;
  background-image: url(${ukraineMapMobile});
  background-repeat: no-repeat;
  background-position: center -55px;
  background-size: 100vw;

  ${respondFrom(
  610,
  css`
      background-image: url(${ukraineMap});
      background-position: right -120px;
      background-size: contain;
    `
)};

  ${respondFrom(
  breakpoints.lg,
  css`
      padding-top: 150px;
      background-image: none;
    `
)};

  ${respondFrom(
  breakpoints.xxl,
  css`
      padding-bottom: 150px;
    `
)};
`;

const HomepageHeroContent = styled.div`
  max-width: 506px;
  z-index: 1;
  position: relative;

  h1 {
    line-height: 48px;
    margin-bottom: 20px;

    ${respondFrom(
      breakpoints.lg,
      css`
            line-height: 72px;
            margin-bottom: 30px;
          `
      )};
    }
`;

const HomepageHeroMap = styled.div`
  position: absolute;
  top: -125px;
  right: 0;
  bottom: -50px;
  width: 50vw;
  display: none;

  ${respondFrom(
  breakpoints.lg,
  css`
      display: block;
      width: 800px;
    `
)};

  ${respondFrom(
  breakpoints.xxl,
  css`
      width: 1000px;
      top: -160px;
    `
)};
`;

const HomepageHero = () => {
  const { t } = useTranslation();

  return (
    <HomepageHeroWrapper>
      <Container>
        <div>
          <HomepageHeroContent>
            <h1>{t('home_hero_title')}</h1>
            <Paragraph htmlAsString={t('home_hero_description')} className="m18" />
          </HomepageHeroContent>
        </div>
      </Container>
      <HomepageHeroMap>
        <img src={ukraineMap} alt={t('home_hero_map_alt')} />
      </HomepageHeroMap>
    </HomepageHeroWrapper>
  );
};

export default HomepageHero;
