import React from 'react';

// === Assets === //
import col1Image from '@assets/img/hands_v2.png';
import col2Image from '@assets/img/people_x2.png';

// === Components === //
import Container from '@components/common/Container';
import TextWithImageColumn from '@components/common/TextWithImageColumn';

// === Helpers === //
import { useTranslation } from 'gatsby-plugin-react-i18next';

// === Styles === //
import styled from '@emotion/styled';
import { breakpoints, respondFrom } from '@styles';
import { css } from '@emotion/react';

const HomepageTextWithImageColumnsWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 50px;

  ${respondFrom(
    breakpoints.md,
    css`
      margin-top: 50px;
      margin-bottom: 50px;
    `
  )};

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-top: 110px;
      margin-bottom: 130px;
    `
  )};
`;

const HomepageTextWithImageColumns = () => {
  const { t } = useTranslation();

  return (
    <HomepageTextWithImageColumnsWrapper>
      <Container>
        <div>
          <TextWithImageColumn
            direction="reverse"
            title={t('home_text_with_image_column_1_title')}
            text={t('home_text_with_image_column_1_text')}
            imageSrc={col1Image}
            imageAlt={t('home_text_with_image_column_1_image_alt')}
          />
          <TextWithImageColumn
            direction="default"
            title={t('home_text_with_image_column_2_title')}
            text={t('home_text_with_image_column_2_text')}
            imageSrc={col2Image}
            imageAlt={t('home_text_with_image_column_2_image_alt')}
          />
        </div>
      </Container>
    </HomepageTextWithImageColumnsWrapper>
  );
};

export default HomepageTextWithImageColumns;
