import React from 'react';

// === Components === //
import Paragraph from '@components/common/Paragraph';

// === Styles === //
import styled from '@emotion/styled';
import { breakpoints, respondFrom } from '@styles';
import { css } from '@emotion/react';

const TextWithImageColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column-reverse;
  margin-bottom: 50px;

  ${respondFrom(
    breakpoints.md,
    css`
      flex-flow: row;
    `
  )};

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-bottom: 100px;
    `
  )};

  &.is-reverse {
    ${respondFrom(
      breakpoints.md,
      css`
        flex-flow: row-reverse;
      `
    )};
  }
`;

const TextWithImageColumnText = styled.div`
  ${respondFrom(
    breakpoints.md,
    css`
      flex: 1;
    `
  )};

  ${respondFrom(
    breakpoints.lg,
    css`
      max-width: 506px;
      margin-right: auto;

      .is-reverse & {
        margin-left: auto;
        margin-right: 0;
      }
    `
  )};
`;

const TextWithImageColumnImg = styled.div`
  margin-bottom: 20px;

  .is-reverse & {
    margin-left: 0;
  }

  ${respondFrom(
    breakpoints.md,
    css`
      flex: 1;
      margin-left: 35px;
      margin-bottom: 0;

      .is-reverse & {
        margin-right: 35px;
      }
    `
  )};
`;

interface TextWithImageColumnProps {
  direction?: 'default' | 'reverse';
  title: string;
  text: string;
  imageSrc: string;
  imageAlt: string;
}

const TextWithImageColumn = ({
  direction = 'default',
  title,
  text,
  imageSrc,
  imageAlt,
}: TextWithImageColumnProps) => {
  return (
    <TextWithImageColumnWrapper className={direction === 'reverse' ? 'is-reverse' : ''}>
      <TextWithImageColumnText>
        <h2>{title}</h2>
        <Paragraph htmlAsString={text} className="m16" />
      </TextWithImageColumnText>
      <TextWithImageColumnImg>
        <img src={imageSrc} alt={imageAlt} />
      </TextWithImageColumnImg>
    </TextWithImageColumnWrapper>
  );
};

export default TextWithImageColumn;
