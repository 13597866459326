import React from 'react';

// === Components === //
import Container from '@components/common/Container';
import Paragraph from '@components/common/Paragraph';

// === Helpers === //
import { useTranslation } from 'gatsby-plugin-react-i18next';

// === Styles === //
import styled from '@emotion/styled';
import { breakpoints, colors, respondFrom } from '@styles';
import { css } from '@emotion/react';

const HomepageSupportForRefugeesWrapper = styled.div`
  background-color: ${colors.ui.grayLight};
  padding: 50px 0;
  ${respondFrom(
    breakpoints.lg,
    css`
      padding: 100px 0;
    `
  )};
`;

const HomepageSupportForRefugeesInner = styled.div`
  position: relative;
  text-align: center;
  z-index: 1;

  > div {
    max-width: 660px;
    margin: auto;
  }
`;

const IframeWrapper = styled.div`
  position: relative;
  text-align: center;
  z-index: 1;
  padding-bottom: 56.25%;
  margin-top: 50px;
  height: 0;
  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const HomepageSupportForRefugees = () => {
  const { t } = useTranslation();

  return (
    <HomepageSupportForRefugeesWrapper>
      <Container>
        <>
          <HomepageSupportForRefugeesInner>
            <h2>{t('home_support_for_refugees_title')}</h2>
            <Paragraph htmlAsString={t('home_support_for_refugees_description')} className="m16" />
          </HomepageSupportForRefugeesInner>
          <IframeWrapper>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/zEMU1krqI7A"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </IframeWrapper>
        </>
      </Container>
    </HomepageSupportForRefugeesWrapper>
  );
};

export default HomepageSupportForRefugees;
