import React from 'react';

// === Assets === //
import emailIcon from '@assets/svg/email-icon.svg';
import chatIcon from '@assets/svg/chat-icon.svg';
import pencilIcon from '@assets/svg/pencil-icon.svg';
import arrowRight from '@assets/svg/arrow-right.svg';
import chevronRight from '@assets/svg/chevron-right.svg';

// === Components === //
import Container from '@components/common/Container';

// === Helpers === //
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as utils from '@utils';

// === Styles === //
import styled from '@emotion/styled';
import { breakpoints, colors, dimensions, fonts, respondFrom } from '@styles';
import { css } from '@emotion/react';

const HHTSIWrapper = styled.div`
  background-color: ${colors.ui.grayLight};
  padding-top: 50px;
  padding-bottom: 50px;

  ${respondFrom(
  breakpoints.lg,
  css`
      padding-top: 90px;
      padding-bottom: 80px;
    `
)};
`;

const HHTSIInner = styled.div`
  h2 {
    text-align: center;
  }
`;

const HHTSIAdditionalText = styled.div`
  text-align: center;

  a {
    text-decoration: none;
    color: ${colors.text.default};
    font-family: ${fonts.extraBold};
    font-weight: 800;
    font-size: 17px;

    img {
      display: inline-block;
      margin-left: 12px;
    }

    ${respondFrom(
  breakpoints.md,
  css`
        font-size: ${dimensions.fontSize.regular}px;
      `
)};
  }

  p {
    font-size: ${dimensions.fontSize.tiny}px;
    margin: 5px 0 0;

    ${respondFrom(
  breakpoints.md,
  css`
        font-size: ${dimensions.fontSize.small}px;
      `
)};
  }
`;

const HomepageHowToSubmitInitiative = () => {
  const { t } = useTranslation();

  return (
    <HHTSIWrapper>
      <Container>
        <HHTSIInner>
          <h2>{t('home_how_to_submit_initiative_title')}</h2>

          <HHTSIAdditionalText>
            <p>{t('home_how_to_submit_initiative_submit_date')}</p>
          </HHTSIAdditionalText>
        </HHTSIInner>
      </Container>
    </HHTSIWrapper>
  );
};

export default HomepageHowToSubmitInitiative;
