import React from 'react';

// === Components === //
import Homepage from '@components/template/Homepage';

// === Helpers === //
import { graphql } from 'gatsby';

const IndexPage = () => {
  return <Homepage />;
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
